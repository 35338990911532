<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-multiple</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Schulklassen</v-toolbar-title>
      <v-spacer />
      <SearchField
        v-model="search"
        hint="Klasse (z.B. 22, G23, W25a), Klassenlehrer*in, SF (z.B BG, S, PAM)"
      ></SearchField>
    </v-app-bar>
    <v-row>
      <v-col>
        <v-card v-if="schoolClass">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{ schoolClass.code }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ schoolClass.studentCount.total }} Schüler:innen ({{
                          schoolClass.studentCount.female
                        }}
                        <v-icon small>mdi-gender-female</v-icon>,
                        {{ schoolClass.studentCount.male }}
                        <v-icon small>mdi-gender-male</v-icon>
                        <span v-if="schoolClass.studentCount.fluid > 0"
                          >,
                          {{ schoolClass.studentCount.fluid }}
                          <v-icon small>mdi-gender-transgender</v-icon></span
                        >)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <PersonItem
                    :value="schoolClass.teacher"
                    clickable
                    description="Klassenlehrperson"
                  />
                  <PersonItem
                    v-if="schoolClass.substituteTeacher"
                    :value="schoolClass.substituteTeacher"
                    clickable
                    description="Stv. Klassenlehrperson"
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
          <v-tabs icons-and-text>
            <v-tabs-slider color="error"></v-tabs-slider>
            <v-tab :to="{ name: 'SchoolClassContact' }"
              >Kontakt<v-icon>mdi-email</v-icon></v-tab
            >
            <v-tab :to="{ name: 'SchoolClassList' }"
              >Liste<v-icon>mdi-format-align-left</v-icon></v-tab
            >
            <v-tab
              :to="{
                name: 'SchoolClassPortrait',
              }"
              >Porträts<v-icon>mdi-account-box</v-icon></v-tab
            >
            <v-tab :to="{ name: 'SchoolClassCourses' }"
              >Kurse<v-icon>mdi-human-male-board</v-icon></v-tab
            >
            <v-tab :to="{ name: 'SchoolClassAbsences' }"
              >Absenzen<v-icon>mdi-bed</v-icon></v-tab
            >
            <v-tab :to="{ name: 'SchoolClassLocker' }">
              Schränke<v-icon>mdi-locker-multiple</v-icon></v-tab
            >
            <v-tab :to="{ name: 'SchoolClassReport' }"
              >Berichte<v-icon>mdi-text-box-multiple</v-icon></v-tab
            >
          </v-tabs>
          <v-divider />
          <router-view></router-view>
        </v-card>
      </v-col>
      <v-col class="col-auto">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <SchoolClass
              v-for="item in filteredItems"
              :key="item.id"
              clickable
              nodetails
              @click="selectSchoolClass(item)"
              :value="item"
            />
            <v-list-item v-if="!loading && filteredItems.length == 0"
              >keine Suchtreffer</v-list-item
            >
            <v-skeleton-loader
              v-if="loading"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PersonItem from "@/components/PersonItem";
import SchoolClass from "@/components/SchoolClass";
import SearchField from "@/components/SearchField";

export default {
  name: "SchoolClasses",
  components: { PersonItem, SchoolClass, SearchField },
  props: ["id"],
  data() {
    return {
      schoolClass: null,
      items: [],
      role: "",
      search: "",
    };
  },
  watch: {
    search() {
      if (this.filteredItems.length === 1) {
        this.selectSchoolClass(this.filteredItems[0]);
        const id = this.filteredItems[0].id;
        this.$router.push({
          name: "SchoolClass",
          params: { id: id.toString() },
        });
      }
    },
  },
  computed: {
    filteredItems() {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter(
        (item) =>
          item.code.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.majorSubjects &&
            item.majorSubjects.includes(this.search.toUpperCase())) ||
          (item.teacher && item.teacher.code === this.search.toLowerCase()) ||
          (item.substituteTeacher &&
            item.substituteTeacher.code === this.search.toLowerCase())
      );
    },
  },
  methods: {
    selectSchoolClass(item) {
      this.schoolClass = item;
    },
  },
  async created() {
    this.loading = true;
    this.items = await this.apiList({ resource: "schoolclass/schoolclass" });
    if (this.id > 0) {
      this.schoolClass = this.items.find(
        (item) => item.id === parseInt(this.id)
      );
    }
    this.loading = false;
  },
};
</script>
